<template>
  <nav class="catalog__nav" :class="{'catalog__nav-fixed': !isInHomeScreen, 'catalog__nav-hide-top-nav': !showTopNav && !isInHomeScreen}">
    <div class="nav__border"></div>
    <div class="nav__top">
      <div class="container">
        <div class="row">

          <div class="col-lg-6 col-xl-3 col-md-4">
            <div class="brand" @click="resetFiltersAction()">
              <img src="/img/bag.svg" class="bag">
              <img src="https://delicity.b-cdn.net/front/img/new_logo_white.svg" class="logo">
            </div>
          </div>

          <div class="col d-none d-md-block">
            <div class="links">
              <a href="/smile" target="_blank">Qui sommes-nous ?</a>
              <a href="https://pro.delicity.co/" target="_blank">Professionnels</a>
              <a href="https://pro.delicity.co/livreurs/" target="_blank">Livreurs</a>
              <a href="https://t.delicity.co/11KLCG" class="hiring" target="_blank">On recrute !</a>
              <a href=""></a>
            </div>
          </div>

        </div>
      </div>
    </div>


    <div class="nav__filters" v-if="!isInHomeScreen">
      <div class="container">

        <div class="filter-group" v-if="catalogData">
          <div class="select__filter select__filter-address" @click="$root.$emit('openFilterAddress')">
            <i class="material-icons notranslate icon">place</i>
            <span>{{catalogData.address}}</span>
            <i class="material-icons notranslate chevron">expand_more</i>
          </div>
          <div class="select__filter select__filter-type" @click="$root.$emit('openFilterType')">
            <i v-if="filter.type === 'delivery'" class="material-icons notranslate icon">moped</i>
            <i v-if="filter.type === 'pickup'" class="material-icons notranslate icon">directions_run</i>
            <span>{{$t(filter.type)}}</span>
            <i class="material-icons notranslate chevron">expand_more</i>
          </div>
          <div class="select__filter select__filter-when" @click="$root.$emit('openFilterTime')">
            <i class="material-icons notranslate icon">update</i>
            <span v-if="filter.when === 'now'">Maintenant</span>
            <span v-if="filter.when === 'schedule_for_later'"> {{scheduled}}</span>
            <i class="material-icons notranslate chevron">expand_more</i>
          </div>
          <div class="search-container">
            <div class="search">
              <i class="material-icons notranslate">search</i>
              <input type="text" data-cy="search-input" @input="executeSearch()" v-model="search" placeholder="Rechercher par nom...">
              <i class="material-icons notranslate search-reset" @click="resetSearch()">close</i>
            </div>
          </div>
        </div>

        <div class="filter-group" v-if="!catalogData">
          <div class="select__filter select__filter-address filter-loading"></div>
          <div class="select__filter select__filter-type filter-loading"></div>
          <div class="select__filter select__filter-when filter-loading"></div>
          <div class="search-container filter-loading"></div>
        </div>


      </div>
    </div>
  </nav>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import moment from 'moment';

export default {
  data() {
    return {
      search: null,
      showTopNav: true
    }
  },
  methods: {
    ...mapActions(['setModeAction', 'setSearchAction', 'resetFiltersAction', 'setSubCategoriesAction']),
    setMode(mode){
      this.setModeAction(mode);
    },
    executeSearch(change){
      if(this.filter.subCategories.length > 0){
        this.setSubCategoriesAction([]);
      }
      this.setSearchAction({search:this.search, change:change});
    },
    resetSearch(change){
      this.setSubCategoriesAction([]);
      this.setSearchAction({search:'', change:change});
    },
    scroll(){
      if(window.scrollY < 100){
        this.showTopNav = true;
      }
      else{
        this.showTopNav = false;
      }

      window.addEventListener('scroll', (e) => {
        if(window.scrollY < 100){
          this.showTopNav = true;
        }
        else{
          this.showTopNav = false;
        }
      });
    }
  },
  computed: {
    ...mapGetters(['filter', 'catalogData', 'isInHomeScreen']),
    scheduled(){
      return moment.unix(this.filter.whenTimestamp).locale("fr").format('ddd D · HH:mm');
    }
  },
  watch: {
    filter: {
      deep: true,
      handler(val){
        this.search = val.search;
      }
    }
  },
  mounted() {
    this.scroll();
  }
}
</script>
